import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, ProductListImage } from '@components'
import {
  Content,
  ImageContainer,
  Solution,
  SolutionsContainer,
  Section
} from './style'

const Solutions = () => {
  const data = useStaticQuery(query)
  const nodes = data.allMarkdownRemark.edges
  return (
    <Section>
      <Container>
        <SolutionsContainer>
          {
            nodes.map(n => {
              const image = `rjesenja/${n.node.frontmatter.image}`
              return (
                <Solution key={n.node.fields.slug} to={`/rjesenja${n.node.fields.slug}`}>
                  <ImageContainer>
                    <ProductListImage
                      alt={`Rješenje - ${n.node.frontmatter.name}`}
                      cover
                      filename={image}
                      style={{ maxHeight: 250 }}
                    />
                  </ImageContainer>
                  <Content>
                    <span>
                      {n.node.frontmatter.name}
                    </span>
                  </Content>
                </Solution>
              )
            })
          }
        </SolutionsContainer>
      </Container>
    </Section>
  )
}

const query = graphql`
  query AllSolutions {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___name]},
      filter: {
        fields: {
          sourceName: {
            eq: "solutions"
          }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            image
          }
        }
      }
    }
  }
`

export default Solutions
