import React from 'react'
import { Link } from 'gatsby'
import { trackGAEvent } from '@utils/functions'
import { Container } from '@components'
import {
  FooterBottom,
  FooterBottomContainer,
  FooterContainer,
  FooterContainerContent,
  Info,
  Menu,
  Menus,
} from './style'
import { useSiteData } from '@utils/functions'
import LogoSrc from '@assets/images/logo_white.svg'
import Linkedin from '@assets/images/linkedin.svg'

const Footer = () => {
  const site = useSiteData()

  return (
    <footer>
      <FooterContainer>
        <Container>
          <FooterContainerContent>
            <Info>
              <img src={LogoSrc} alt="FSB d.o.o." height={60} />
              <p>
                <a href="https://www.google.com/maps/place/FSB+d.o.o./@45.811092,15.873422,17z/data=!3m1!4b1!4m5!3m4!1s0x4765d1a06eb53a81:0xf767673f33bcec1!8m2!3d45.811092!4d15.875616"  onClick={() => trackGAEvent({
                  category: 'contact',
                  action: 'address',
                  label: 'footer',
                })}>
                  {site.address},<br/>
                  {site.postalCode} {site.addressLocality}, Hrvatska
                </a>
              </p>
              <p>
                <a href={`mailto:${site.email}`} onClick={() => trackGAEvent({
                  category: 'contact',
                  action: 'email',
                  label: 'footer',
                  isConversion: true,
                })}>
                  {site.email}
                </a>
              </p>
              <p>
                <a href={`tel:${site.phoneLink}`} onClick={() => trackGAEvent({
                  category: 'contact',
                  action: 'phone',
                  label: 'footer',
                  isConversion: true,
                })}>
                  {site.phone}
                </a>
              </p>
              <p>
                <a href="https://www.linkedin.com/company/fsb-d.o.o./" rel="noopener noreferrer" target="_blank" onClick={() => trackGAEvent({
                  category: 'social',
                  action: 'visit',
                  label: 'linkedin',
                })}>
                  <img src={Linkedin} alt="Linkedin" height="30px" />
                </a>
              </p> 
            </Info>
            <Menus>
              <Menu>
                <h5>
                  <Link to="/usluge">
                    Usluge
                  </Link>
                </h5>
                <ul>
                  <li>
                    <Link to="/usluge/izvodjenje-radova-protupozarne-zastite/">
                      Izvođenje radova protupožarne zaštite
                    </Link>
                  </li>
                  <li>
                    <Link to="/usluge/optimizacija-troskova-protupozarne-zastite/">
                      Optimizacija troškova protupožarne zaštite
                    </Link>
                  </li>
                  <li>
                    <Link to="/usluge/edukacija/">
                      Edukacija - jamstvo izvrsnosti i sigurnosti
                    </Link>
                  </li>
                  <li>
                    <Link to="/usluge/laboratorijsko-ispitivanje/">
                      Laboratorijsko ispitivanje
                    </Link>
                  </li>
                </ul>
              </Menu>
              <Menu>
                <h5>
                  <Link to="/proizvodi/">
                    Proizvodi
                  </Link>
                </h5>
                <ul>
                  <li>
                    <Link to="/proizvodi/protupozarno-brtvljenje/">
                      Protupožarno brtvljenje
                    </Link>
                  </li>
                  <li>
                    <Link to="/proizvodi/protupozarna-boja-i-premaz/">
                      Protupožarna boja i premaz
                    </Link>
                  </li>
                  <li>
                    <Link to="/proizvodi/protupozarne-ploce/">
                      Protupožarne ploče
                    </Link>
                  </li>
                </ul>
              </Menu>
            </Menus>
          </FooterContainerContent>
        </Container>
      </FooterContainer>
      <FooterBottomContainer>
        <Container>
          <FooterBottom>
            <div>
              <Link to="/zastita-privatnosti/">
                Zaštita privatnosti
              </Link>
              <Link to="/impressum/">
                Impressum
              </Link>
            </div>
            <span>
              &copy; <i>Sva prava pridržana</i> 2010 - {new Date().getFullYear()}
            </span>
          </FooterBottom>
        </Container>
      </FooterBottomContainer>
    </footer>
  )
}

export default Footer