import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Container } from '@components'
import { getCookie, setCookie } from './utils'
import { C, Content } from "./style"


const CookiesNotification = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!getCookie('_consent')) {
      setVisible(true)
    }
  }, [setVisible])

  const handleClose = useCallback(() => {
    setCookie('_consent', 1, 365)
    setVisible(false)
  },[setVisible])

  if (!visible) {
    return null
  }

  return (
    <C>
      <Container>
        <Content>
          <p>
            Ova web stranica koristi kolačiće kako bi modificirala web stranicu i povezane usluge na način koji je više prilagođen korisniku i analizirala njihovu upotrebu. Dodatne informacije o kolačićima i kako ih koristimo nalaze se u odjeljku "Kolačići" u <Link to="/zastita-privatnosti">Pravilima o zaštiti privatnosti</Link>. Ako nastavite aktivno koristiti ovu web stranicu, prihvaćate našu upotrebu kolačića.
          </p>
          <button onClick={handleClose}>
            Prihvaćam
          </button>
        </Content>
      </Container>
    </C>
  )
}

export default CookiesNotification
