import React from 'react'
import { Fade } from 'react-reveal'
import {
  Container,
  ResponsiveImage,
} from '@components'
import {
  Content,
  ImageContainer,
  Section,
  Text,
} from './style'

const Construction = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Text>
            <Fade bottom distance="50px">
              <h3>
                Edukacija radnika monterskih tvrtki
              </h3>
              <p>
                Tvrtka FSB d.o.o. u svrhu sprječavanja neočekivanih neželjenih događaja na gradilištu koja se odnose na ugradbu pasivne protupožarne zaštite osmislila je edukaciju za radnike monterskih tvrtki kroz jednodnevni seminar na kojem će polaznici dobiti sve korisne informacije koje se tiču pravilne ugradnje proizvoda pasivne protupožarne zaštite.
              </p>
              <p>
                Monteri će, osim teorijske podloge, dobiti i praktično znanje uz pomoć video uradaka.
              </p>
              <h3>
                Edukacija projektanata i nadzornih inženjera
              </h3>
              <p>
                Kako bi projektanti i nadzorni inženjeri što kvalitetnije i uspješnije obavili svoj dio posla koji se tiče implementacije pasivne protupožarne zaštite u svoje projekte, tvrtka FSB d.o.o. osmislila je jednodnevni seminar namijenjen edukaciji projektanata i nadzornih inženjera.
              </p>
              <p>
                Jednodnevni seminar uključuje teorijsku podlogu o aktualnim zakonskim okvirima koji su na snazi u Republici Hrvatskoj te praktične primjere ugradnje proizvoda pasivne protupožarne zaštite s ciljem kvalitetnijeg i sigurnijeg obavljanja nadzora i projektiranja. 
              </p>
            </Fade>
          </Text>
          <ImageContainer>
            <ResponsiveImage filename="edu.jpg" alt="Edukacija - FSB d.o.o. protupožarna zaštita" />
          </ImageContainer>
        </Content>
      </Container>
    </Section>
  )
}

export default Construction
