import React from 'react'
import { Fade } from 'react-reveal'
import {
  Container,
  ResponsiveImage,
} from '@components'
import {
  Content,
  Highlight,
  ImageContainer,
  List,
  Section,
  Text,
} from './style'

const Optimization = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Text>
            <Fade bottom distance="50px">
              <Highlight>
                Naš tim sastavljen od vrhunskih inženjera ima potrebna znanja, vještina i, najvažnije, bogato iskustvo u optimizaciji i odabiru odgovarajućih rješenja za konkretan projekt. Svakom projektu pristupamo individualno.
              </Highlight>
            </Fade>
            <Fade bottom distance="50px">
              <p>
                Surađujući s multidisciplinarnim timom (glavni projektant, nadzorni inženjer, projektant zaštite od požara) analiziramo projektni zadatak sagledavajući ga sa svih aspekata (arhitektonski izgled, građevinska fizika, statika i zaštita od požara). Poznajući široku paletu potencijalno mogućih tehničkih rješenja, uz suglasnost svih sudionika u gradnji, odabiremo najoptimalnije cjenovno rješenje. Na taj način osiguravamo investitorima implementaciju certificirane kvalitete, odnosno najboljih tehničkih rješenja, uz konkurentan cjenovni imidž. U konačnici, našim klijentima ostvarujemo značajne uštede. 
              </p>
              <p>
                Uštedu našim klijentima ostvarujemo i u suradnji s našim partnerima nudeći usluge proračuna konstrukcija na djelovanje realnog požara (Computational Fluid Dynamics – CFD) čime se štedi vrijeme (ubrzanje izvođenja građevinskih radova) i novac (smanjenje troškova protupožarne zaštite).
              </p>
              <List>
                <p>
                  Nudimo i sljedeće usluge:
                </p>
                <ul>
                  <li>
                    operativno planiranje (budžetiranje) troškova izgradnje u fazi nuđenja, 
                  </li>
                  <li>
                    izrada troškovnika,
                  </li>
                  <li>
                    izrada izvedbenih detalja ovisno o specifičnosti situacije prije i tijekom izgradnje.
                  </li>
                </ul>
              </List>
            </Fade>
          </Text>
          <ImageContainer>
            <ResponsiveImage filename="opti.jpg" alt="Optimizacija troškova protupožarne zaštite - FSB d.o.o. protupožarna zaštita" />
          </ImageContainer>
        </Content>
      </Container>
    </Section>
  )
}

export default Optimization
