import React from 'react'
import { Link } from 'gatsby'
import { Container, Service } from '@components'
import { ServicesContainer } from './style'

const Services = () =>  (
  <ServicesContainer>
    <Container small>
      <Service
        alt="Izvođenje radova protupožarne zaštite - FSB d.o.o."
        img="zgrada.jpg"
        to="/usluge/izvodjenje-radova-protupozarne-zastite/"
      >
        <h2>
          Izvođenje radova protupožarne zaštite
        </h2>
        <p>
          U svrhu ispunjavanja visoko postavljenih zahtjeva surađujemo s najboljim monterskim tvrtkama u Republici Hrvatskoj.
        </p>
        <Link to="/usluge/izvodjenje-radova-protupozarne-zastite/">
          Više informacija
        </Link>
      </Service>
      <Service
        alternative
        alt="Optimizacija troškova protupožarne zaštite - FSB d.o.o."
        img="opti.jpg"
        to="/usluge/optimizacija-troskova-protupozarne-zastite/"
      >
        <h2>
          Optimizacija troškova protupožarne zaštite
        </h2>
        <p>
          Projekte analiziramo s multidisciplinarnim timom i poznavanjem široke paleta mogućih tehničkih rješenja, uz suglasnost svih sudionika u gradnji, odabiremo optimalno cjenovno rješenje.
        </p>
        <Link to="/usluge/optimizacija-troskova-protupozarne-zastite/">
          Više informacija
        </Link>
      </Service>
      <Service
        alt="Edukacija - jamstvo izvrsnosti i sigurnosti - FSB d.o.o. protupožarna zaštita"
        img="edu.jpg"
        to="/usluge/edukacija/"
      >
        <h2>
          Edukacija - jamstvo izvrsnosti i sigurnosti
        </h2>
        <p>
          Radi sprječavanja neželjenih događaja koji se odnose na montažu pasivne protupožarne zaštite osmislili smo edukaciju za radnike monterskih tvrtki, projektante i nadzorne inženjere.
        </p>
        <Link to="/usluge/edukacija/">
          Više informacija
        </Link>
      </Service>
      <Service
        alternative
        alt="Laboratorijsko ispitivanje - FSB d.o.o. protupožarna zaštita"
        img="lab.jpg"
        to="/usluge/laboratorijsko-ispitivanje/"
      >
        <h2>
          Laboratorijsko ispitivanje
        </h2>
        <p>
          Osmišljavamo vlastite sustave pasivne protupožarne zaštite te iste ispituje u LTM – laboratoriju za toplinska mjerenja.
        </p>
        <Link to="/usluge/laboratorijsko-ispitivanje/">
          Više informacija
        </Link>
      </Service>
    </Container>
  </ServicesContainer>
)

export default Services
