import React from 'react'
import { Link } from 'gatsby'
import { Result } from './style.js'

const SearchResults = ({ results }) => {
  if (!results) {
    return (
      <div style={{ marginBottom: 50 }}>
        Upišite pojam za pretragu
      </div>
    )
  }

  if (!results.length) {
    return (
      <div style={{ marginBottom: 50 }}>
        Nema traženih rezultata
      </div>
    )
  }

  return results.map(result => {
    const url = result.pathname
    const indexOfFirst = result._highlightResult.content.value.indexOf('<em>')
    const content = `...${result._highlightResult.content.value.substring(indexOfFirst - 150, indexOfFirst + 150)}...`
    return (
      <Result key={result.objectID}>
        <Link
          dangerouslySetInnerHTML={{ __html: result._highlightResult.url.value}}
          style={{ textDecoration: 'none', fontSize: 13, color: '#777' }}
          to={url}
        />
        <h3>
          <Link to={url} dangerouslySetInnerHTML={{ __html: result._highlightResult.title.value }} />
        </h3>
        <p dangerouslySetInnerHTML={{ __html: result._highlightResult.description.value }} />
        {
          indexOfFirst !== -1 &&
          <p style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: content }} />
        }
      </Result>
    )
  })
}

export default SearchResults