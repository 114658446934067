import React from 'react'
import { Fade } from 'react-reveal'
import { graphql, Link, useStaticQuery } from 'gatsby'
import {
  Container,
  ResponsiveImage,
} from '@components'
import Arrow from '@assets/images/arrow.svg'
import {
  Content,
  Project,
  ProjectsContainer,
  Section,
} from './style'

const References = () => {
  const data = useStaticQuery(query)
  const nodes = data.allMarkdownRemark.edges
  return (
    <Section>
      <Container>
        <Fade bottom distance="50px">
          <h2>
            Najnoviji projekti
          </h2>
          <ProjectsContainer>
            {
              nodes.map(n => {
                const image = `projekti/${n.node.frontmatter.image}`
                return (
                  <Project key={n.node.fields.slug}>
                    <Link to={`/projekti${n.node.fields.slug}`}>
                      <ResponsiveImage
                        alt={`Projekt - ${n.node.frontmatter.name}`}
                        filename={image}
                      />
                    </Link>
                    <Content>
                      <span>
                        {n.node.frontmatter.name}
                      </span>
                      <Link to={`/projekti${n.node.fields.slug}`}>
                        <span>
                          Više informacija
                        </span>
                        <img src={Arrow} alt="Više informacija" height={22} />
                      </Link>
                    </Content>
                  </Project>
                )
              })
            }
          </ProjectsContainer>
        </Fade>
      </Container>
    </Section>
  )
}

const query = graphql`
  query LatestProjects {
    allMarkdownRemark(
      limit: 3
      filter: {
        fields: {
          sourceName: {
            eq: "projects"
          }
        }
      }
      sort: {
        fields: [frontmatter___projectOrder]
        order: DESC
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            image
          }
        }
      }
    }
  }
`

export default References
