import styled from 'styled-components'
import { primaryColor } from '@styles/theme'

export const Input = styled.input`
  padding: 10px;
  margin: 30px 0;
  width: 100%;
  font-size: 18px;
  box-sizing: border-box;

  &:focus {
    outline: 1px solid ${primaryColor};
  }
`
