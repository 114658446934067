import React from "react"
import { Link } from "gatsby"
import { Container } from "@components"
import { Content } from "./style"

const NotFound = () => {
  return (
    <Container>
      <Content>
        <p>
          Stranica koju ste tražili, nažalost nije pronađena. Stranicu nije
          moguće pronaći jer je možda privremeno nedostupna, uklonjena ili joj
          je promijenjeno ime.
        </p>
        <p>
          Vratite se na <Link to="">Početnu stranicu</Link>
        </p>
      </Content>
    </Container>
  )
}

export default NotFound
