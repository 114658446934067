import React from 'react'
import { Fade } from 'react-reveal'
import { Container, ResponsiveImage } from '@components'
import {
  Content,
  Image,
  Section,
  Text,
} from './style'

const About = () => (
  <Section>
    <Container>
      <Fade bottom cascade distance="50px">
        <Content>
          <Text>
            <p>
                Prema Zakonu o zaštiti od požara građevinski i industrijski objekti moraju biti napravljeni tako da se spriječi širenje vatre i dima te da se osigura spašavanje ljudi i imovine. Neusklađeno planiranje i loša izvedba sustava protupožarne zaštite može u slučaju požara prouzročiti značajnu direktnu i indirektnu štetu, kao i onemogućiti učinkovito gašenje požara.
              
            </p>
            <p>
              Kako bi se umanjile posljedice požara, FSB nudi ne samo sistemska rješenja već i cjelovitu tehničku podršku tijekom planiranja pa sve do završetka izgradnje. Mi smo dokaz kako iz usklađenog djelovanja teorije i prakse korištenjem ispitanih sustava mogu proizaći odgovarajuće izvedbene varijante protupožarne zaštite. Odabrani proizvodi i sustavi rabe se za pasivnu protupožarnu zaštitu u graditeljstvu, elektroenergetskim postrojenjima (hidroelektrane, termoelektrane – energane, trafostanice), rafinerijama i sl. Vrlo visoka sigurnost uz optimalizaciju troškova najveći nam je kriterij pri odabiru materijala i sustava. Također smo jako dobro upoznati s novom regulativom i direktivama Europske komisije pa u skladu s tim pripremamo cjelokupnu izvedbenu dokumentaciju. Cilj nam je vrlo visoka razina zaštite od požara pa pažljivo i u skladu s ostalim zahtjevima, poput fizike i statike zgrade te estetskog oblikovanja, izvodimo radove, koje eventualno potpomažemo i tehničkim razradama.
            </p>
          </Text>
          <Image>
            <ResponsiveImage
              alt="Radnici FSB d.o.o. - protupožarna zaštita na gradilištu"
              filename="about.jpg"
            />
          </Image>
        </Content>
      </Fade>
    </Container>
  </Section>
)

export default About
