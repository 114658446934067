import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, ProductListImage } from '@components'
import {
  Content,
  ImageContainer,
  Project,
  ProjectsContainer,
  Section
} from './style'

const Projects = () => {
  const data = useStaticQuery(query)
  const nodes = data.allMarkdownRemark.edges
  return (
    <Section>
      <Container>
        <ProjectsContainer>
          {
            nodes.map(n => {
              const image = `projekti/${n.node.frontmatter.thumb}`
              return (
                <Project key={n.node.fields.slug} to={`/projekti${n.node.fields.slug}`}>
                  <ImageContainer>
                    <ProductListImage
                      alt={`Projekt - ${n.node.frontmatter.name}`}
                      cover
                      filename={image}
                      style={{ maxHeight: 250 }}
                    />
                  </ImageContainer>
                  <Content>
                    <span>
                      {n.node.frontmatter.name}
                    </span>
                  </Content>
                </Project>
              )
            })
          }
        </ProjectsContainer>
      </Container>
    </Section>
  )
}

const query = graphql`
  query AllProjects {
    allMarkdownRemark(
      filter: {
        fields: {
          sourceName: {
            eq: "projects"
          }
        }
      }
      sort: {
        fields: [frontmatter___projectOrder]
        order: DESC
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            thumb
          }
        }
      }
    }
  }
`

export default Projects
