import React, { useCallback, useRef, useState } from 'react'
import { Form } from './style'
import { useClickOutside } from '@utils/functions'

const HeaderSearch = () => {
  const inputRef = useRef(null)
  const formRef = useRef(null)
  const [q, setQ] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)

  const handleChange = useCallback((e) => {
    setQ(e.target.value)
  }, [])

  const handleClick = useCallback((e) => {
    if (e.view.innerWidth < 641) {
      window.location = '/pretraga'
      return
    }
    if (!isExpanded) {
      setIsExpanded(true)

      if (inputRef.current) {
        inputRef.current.focus()
      }
      return
    }

    if (!q) {
      return
    }

    window.location = `/pretraga?q=${q}`
  }, [isExpanded, q])

  const handleSearch = useCallback((e) => {
    e.preventDefault()
    window.location = `/pretraga?q=${q}`
  }, [q])

  useClickOutside(formRef, () => setIsExpanded(false));

  return (
    <Form ref={formRef} onSubmit={handleSearch} expanded={isExpanded}>
      <input
        onChange={handleChange}
        placeholder="Upišite pojam"
        ref={inputRef}
        type="text"
        value={q}
      />
      <button onClick={handleClick} type="button">
        <img
          alt=""
          height={20}
          src="data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjNDY0NjQ2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAwIDEwMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGQ9Ik05MC40LDg0TDc1LjgsNjkuNUM4Ny4yLDU0LjksODYsMzQuMSw3Mi45LDIxYy02LjktNi45LTE2LjEtMTAuNy0yNS45LTEwLjdjLTkuOCwwLTE5LDMuOC0yNS45LDEwLjcgIGMtNi45LDYuOS0xMC43LDE2LjEtMTAuNywyNS45YzAsOS44LDMuOCwxOSwxMC43LDI1LjljNi45LDYuOSwxNi4xLDEwLjcsMjUuOSwxMC43YzguMSwwLDE2LjEtMi43LDIyLjUtNy44TDg0LDkwLjQgIGMwLjksMC45LDIsMS4zLDMuMiwxLjNjMS4yLDAsMi4zLTAuNSwzLjItMS4zQzkyLjIsODguNyw5Mi4yLDg1LjgsOTAuNCw4NHogTTc0LjYsNDYuOWMwLDcuNC0yLjksMTQuMy04LjEsMTkuNSAgYy01LjIsNS4yLTEyLjIsOC4xLTE5LjUsOC4xcy0xNC4zLTIuOS0xOS41LTguMWMtNS4yLTUuMi04LjEtMTIuMi04LjEtMTkuNWMwLTcuNCwyLjktMTQuMyw4LjEtMTkuNXMxMi4yLTguMSwxOS41LTguMSAgYzcuNCwwLDE0LjMsMi45LDE5LjUsOC4xQzcxLjcsMzIuNiw3NC42LDM5LjUsNzQuNiw0Ni45eiI+PC9wYXRoPjwvc3ZnPg=="
        />
      </button>
    </Form>
  )
}

export default HeaderSearch