import React from 'react'
import { Fade } from 'react-reveal'
import { Container,  } from '@components'
import {
  Content,
  Figure,
  Section,
} from './style'

const Figures = () => (
  <Section>
    <Container>
      <Fade bottom distance="50px">
        <Content>
          <Figure>
            <div>
              Godina iskustva
            </div>
            <div>
              30+
            </div>
          </Figure>
          <Figure>
            <div>
              Broj projekata
            </div>
            <div>
              300+
            </div>
          </Figure>
          <Figure>
            <div>
              Broj zaposlenih
            </div>
            <div>
              10+
            </div>
          </Figure>
        </Content>
      </Fade>
    </Container>
  </Section>
)

export default Figures
