import styled from 'styled-components'
import { primaryColor } from '@styles/theme'
import { transition } from '@styles/utils'

export const Result = styled.div`
  margin-bottom: 30px;
  max-width: 800px;

  @media (max-width: 640px) {
    padding: 5px 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

  h3 {
    margin: 5px 0 10px;

    @media (max-width: 640px) {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
    }
  }

  p {
    margin: 0 0 10px;
    line-height: 1.4;

    @media (max-width: 640px) {
      display: none;
    }
  }

  em {
    background: rgba(0,0,0,0.1);

    @media (max-width: 640px) {
      background: transparent;
    }
  }

  a {
    color: inherit;
    transition: ${transition('color')};

    &:hover {
      color: ${primaryColor};
    }
  }


`
