import React from 'react'
import { Fade } from 'react-reveal'
import {
  Container,
  ResponsiveImage,
} from '@components'
import {
  Box,
  ImageContainer,
  FirstRow,
  LastRow,
  ServicesContainer,
} from './style'

const HomeServices = () => (
  <ServicesContainer>
    <Container>
      <FirstRow>
        <Box theme="light">
          <Fade bottom distance="50px">
            <h3>
              Izvođenje radova protupožarne zaštite
            </h3>
            <p>
              U svrhu ispunjavanja visoko postavljenih zahtjeva surađujemo s najboljim monterskim tvrtkama u Republici Hrvatskoj. Svi naši partneri prolaze dodatnu obuku za izvođenje naših rješenja.
            </p>
            <a href="/usluge/izvodjenje-radova-protupozarne-zastite/">
              Više
            </a>
          </Fade>
        </Box>
        <ImageContainer>
          <ResponsiveImage
            filename="zgrada.jpg"
          />
        </ImageContainer>
      </FirstRow>
    <LastRow>
      <Box theme="dark">
        <Fade bottom distance="50px">
            <h3>
              Optimizacija troškova protupožarne zaštite
            </h3>
            <p>
              Projekt analiziramo s multidisciplinarnim timom. Poznajući široku paletu mogućih tehničkih rješenja, uz suglasnost svih sudionika u gradnji, odabiremo optimalno cjenovno rješenje.
            </p>
            <a href="/usluge/optimizacija-troskova-protupozarne-zastite/">
              Više
            </a>
          </Fade>
        </Box>
        <Box theme="red">
          <Fade bottom distance="50px">
            <h3>
              Edukacija radnika
            </h3>
            <p>
              Tvrtka FSB d.o.o. radi sprječavanja neželjenih događaja koji se odnose na montažu pasivne protupožarne zaštite osmislila je edukaciju za radnike monterskih tvrtki, projektante i nadzorne inženjere.
            </p>
            <a href="/usluge/edukacija/">
              Više
            </a>
          </Fade>
        </Box>
      </LastRow>
    </Container>
  </ServicesContainer>
)

export default HomeServices
