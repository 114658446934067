import React from 'react'
import { Container } from '@components'
import { Content } from './style'

const Privacy = () => (
  <Container>
    <Content>
      <p>
        FSB d.o.o. obvezuje se na zaštitu vaših osobnih podatka i zadržava ih samo onoliko dugo koliko su potrebni. U nastavku je objašnjeno kako prikupljamo, koristimo i dijelimo vaše podatke, zajedno s opcijama koje imate u vezi kontroliranja tih podataka.
      </p>
      <p>
        Molimo vas da politiku privatnosti povremeno provjerite s obzirom na moguće izmjene.
      </p>
      <h2>
        Općenito
      </h2>
      <p>
        Prikupljamo više vrsta informacija, ovisno o kontekstu i načinu na koji ih koristimo, a to su:
      </p>
      <ul>
        <li>
          Podaci koje vi pružate (osobni podaci), i
        </li>
        <li>
          Automatski prikupljeni podaci.
        </li>
      </ul>
      <p>
        Imajte na umu da je tvrtka FSB d.o.o kontrolor vaših osobnih podataka. Načela navedena u ovim pravilima o privatnosti primjenjuju se na sve instance u kojima tvrtka FSB d.o.o prima vaše osobne podatke kao kontrolor podataka u svrhu obrade podataka kako biste mogli sudjelovati u raznim aktivnostima dostupnim na ovoj web stranici ili kako je navedeno u nastavku.
      </p>
      <h3>
        Osobni podaci
      </h3>
      <p>
        Kao korisnik ili posjetitelj naše web stranice vi možete pružiti osobne podatke, koje mi možemo prikupiti. Primjeri osobnih podataka uključuju ime, adresu i adresu e-pošte. Osobni podaci uključuju i druge informacije, kao što su geografsko područje ili postavke, kada su takve informacije povezane s informacijama koje identificiraju određenu osobu. Osobne podatke možete nam pružiti na različite načine korištenjem naše usluge.
      </p>
      <h3>
        Automatski prikupljeni podaci
      </h3>
      <p>
        Kao i većina pružatelja mrežnih usluga, prikupljamo informacije koje obično omogućuju web preglednici, mobilni uređaji i poslužitelji. To su primjerice vrsta preglednika, IP adresa, jedinstveni identifikatori uređaja, jezična sklonost, datum i vrijeme pristupa, operativni sustav i informacije o mobilnoj mreži. Ti podaci se prikupljaju kada koristite naše usluge.
      </p>
      <h2>
        Kako koristimo podatke
      </h2>
      <p>
        Vaše podatke koristimo na sljedeći način:
      </p>
      <ul>
        <li>
          Da bismo učinkovito odgovorili na vaš upit
        </li>
        <li>
          Da bismo vam pružili tražene usluge
        </li>
        <li>
          Za dodatno razvijanje naših usluga
        </li>
        <li>
          Za praćenje i analiziranje trendova i bolje razumijevanje interakcije korisnika s našim uslugama, što nam pomaže u poboljšanju naših usluga i olakšavanju njihova korištenja
        </li>
        <li>
          Za praćenje i zaštitu sigurnosti naših usluga, otkrivanje i sprečavanje prijevara i drugih nezakonitih aktivnosti, sprečavanje zaprimanja neželjene pošte, itd.
        </li>
      </ul>
      <h2>
        Sadržaj politika privatnosti
      </h2>
      <p>
        Prikupljamo samo one osobne podatke koji su nam dobrovoljno dostavljeni. Ne zahtijevamo od vas da nam pošaljete te podatke kako bismo vam omogućili pristup na svoje stranice i ne tražimo da otkrijete više podataka no što je doista potrebno za sudjelovanje u nekoj aktivnosti na našim stranicama.
      </p>
      <p>
        Ne prodajemo, ne prenosimo i ne otkrivamo podatke koje prikupimo na svojim internetskim stranicama nikome izvan društva FSB d.o.o, osim:
      </p>
      <ul>
        <li>
          u slučajevima kada je to potrebno radi izvršavanja naših zakonskih obveza,
        </li>
        <li>
          u slučajevima kada je to potrebno radi zaštite vašeg života ili tjelesnog integriteta, a vi niste u mogućnosti dati svoj pristanak za obradu svojih osobnih podataka,
        </li>
        <li>
          u slučajevima kada je to nužno radi ispunjenja zadataka koji se izvršavaju u javnom interesu,
        </li>
        <li>
          u slučajevima kada ste vi sami objavili te podatke ( čl. 7., st. 3., toč. 1., 2., 3. i 4. Zakona o zaštiti osobnih podataka ),
        </li>
        <li>
          u slučajevima kad je uključenost druge strane neophodna za ispravno funkcioniranje stranica. U takvim slučajevima, a prije dostave osobnih podataka, druga se strana FSB d.o.o. obvezuje na čuvanje poslovne tajne,
        </li>
        <li>
          u slučaju eventualne prodaje ili pravnog sljedništva FSB d.o.o. zadržavamo pravo prodaje ili prenošenja vaših podataka trećoj strani koja:
          <ul>
            <li>
              provodi svoje poslovanje u okviru sličnih aktivnosti ili usluga,
            </li>
            <li>
              pristaje biti sljednik FSB d.o.o. u pogledu očuvanja i zaštite prikupljenih podataka,
            </li>
            <li>
              pristaje na obveze ove politike o zaštiti osobnih podataka. Popunjavajući formulare na našoj stranici jamčite da su informacije koje ste priložili točne te da ste u cijelosti suglasni s odredbama naše Politike privatnosti i u njoj navedenim uvjetima.
            </li>
          </ul>
        </li>
      </ul>
      <h2>
        Tajnost podataka
      </h2>
      <p>
        Želimo jasno reći da prilikom posjeta ovim stranicama vaši osobni podaci ostaju tajni, osim ako ih ne želite dobrovoljno otkriti. Obvezujemo se da drugim stranama nećemo otkrivati podatke koje smo zaprimili, osim u slučajevima navedenima u prethodnome poglavlju.
      </p>
      <h2>
        Statistika poslužitelja
      </h2>
      <p>
        Naši poslužitelji koriste Google Analytics kao statistički softver. Takvi statistički programi omogućuju nam mjerenje prometa na web stranici i stvaranje korisničkih navigacijskih izvješća. Google djeluje neovisno od nas i ima vlastitu pravila o privatnosti, što preporučujemo da pregledate. Google može koristiti podatke prikupljene putem Google Analyticsa kako bi procijenio aktivnost korisnika i posjetitelja na našoj web stranici. Dodatne informacije potražite u ovom <a href="https://support.google.com/analytics/answer/6004245?hl=en&ref_topic=2919631" target="_blank" rel="noopener noreferrer">članku</a>.
      </p> 
      <p>
        Možete zatražiti isključenje iz prikupljanja informacija o vašem posjetu web stranicama pomoću <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">ove opcije</a>.
      </p>
      <h2>
        Upotreba kolačića (cookies)
      </h2>
      <p>
        Kako bi se olakšalo pregledavanje naših internetskih stranica, naš poslužitelj globalne mreže koristi kolačiće. Radi se o malim tekstualnim datotekama koje poslužitelj postavlja na računalo korisnika u svrhu omogućavanja stranici da prikaže informacije prilagođene vašim potrebama. Kolačići se ne mogu koristiti za pokretanje programa ili postavljanje virusa na vaše računalo. Kolačići koje naš internetski poslužitelj postavlja brišu se automatski s vašeg računala na kraju sesije, odnosno onog trenutka kada napustite naše stranice. Pregled naših stranica moguć je i bez uporabe kolačića, ako je vaš internetski preglednik tako podešen, no funkcionalnost može biti značajno umanjena.
      </p> 
      <h2>
        Sigurnost
      </h2>
      <p>
        Radi sigurnosti podataka na ovoj adresi te kako bismo osigurali da ova usluga bude pristupačna svim korisnicima, ovaj računalni sustav koristi softver koji prati posjete na mreži te prepoznaje neovlaštene pokušaje otpreme ili izmjene podataka, kao i one koji bi mogli prouzročiti štetu na neki drugi način. Neovlašteni pokušaji otpreme ili izmjene podataka na ovoj lokaciji strogo su zabranjeni.
      </p>
      <p>
        Koristimo odgovarajuće poslovne sustave i procedure kako bismo zaštitili osobne podatke koje nam dajete. Također koristimo sigurnosne postupke i tehnička i fizička ograničenja za pristup i korištenje osobnih podataka na našim poslužiteljima.
      </p>
      <h2>
        Kontrola
      </h2>
      <p>
        Ako želite zatražiti pregled svih osobnih podataka koje smo od vas dobili, možete nas kontaktirati putem <a href="mailto:dpo@fsb-zagreb.hr">emaila</a>.
      </p>
      <p>
        Možete odbiti dijeliti neke podatke s nama, u tom slučaju vam možda nećemo moći pružiti neke od naših usluga.
      </p>
      <p>
        Ako smatrate da su informacije koje imamo o vama netočne ili nepotpune, molimo da nas što prije kontaktirate. Brzo ćemo ispraviti sve informacije za koje se utvrdi da su netočne.
      </p>
      <p>
        U bilo kojem trenutku možete prigovoriti obradi vaših osobnih podataka, na temelju legitimnih osnova, osim ako je drukčije dopušteno primjenjivim zakonom. Ako smatrate da je vaše pravo na privatnost prekršeno prema važećim zakonima o zaštiti podataka, obratite se našem službeniku za zaštitu podataka na dpo@fsb-zagreb.hr.
      </p>
      <p>
        Vaše podatke nećemo prodavati, distribuirati ili dati u zakup trećim osobama pod bilo kojim okolnostima.
      </p>
      <h2>
        Poveznice
      </h2>
      <p>
        Činimo sve što je u našoj moći kako bismo osigurali da sva preusmjeravanja s naših internetskih stranica upute vas i/ili vaše dijete na internetske stranice čiji je sadržaj kvalitetan u smislu da ne potiče negativnosti. Međutim, stranice i adrese na mreži brzo se mijenjaju i ne možemo uvijek jamčiti za sadržaj svake adrese na koju vas usmjerimo.
      </p>
      <p>
        Naša web stranica može sadržavati poveznice na web stranice i usluge koje pružaju treće strane. Sve informacije koje pružate na web stranicama ili uslugama trećih strana pružaju se izravno operaterima takvih usluga i podložne su politikama tih operatera. Nismo odgovorni za sadržaj ili privatnost i sigurnosne postupke i pravila web stranica ili usluga trećih strana koje su s našom web stranicom povezane poveznicom. Preporučujemo vam da saznate više o pravilima privatnosti i sigurnosti trećih strana prije nego im date bilo kakve informacije.
      </p>
      <h2>
        Promjene i prestanak uvjeta korištenja
      </h2>
      <p>
        FSB d.o.o. pridržava pravo izmjene ili prekida svih ili bilo kojeg dijela ove internetske lokacije te uvjeta korištenja u bilo kojem trenutku. Promjene stupaju na snagu objavljivanjem na ovoj adresi ili kada su korisnici o njima obaviješteni. Uvjeti korištenja primjenjuju se do raskida istih od vaše strane ili od strane FSB d.o.o. Vaš raskid, moguć u bilo kojem trenutku, znači prestanak korištenja ovih stranica i poništenje svih materijala i sadržaja preuzetih i korištenih s iste internetske lokacije.
      </p>
    </Content>
  </Container>
)

export default Privacy
