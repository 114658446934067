import React from 'react'
import { Fade } from 'react-reveal'
import { Container, ResponsiveImage } from '@components'
import {
  Content,
  ImageContainer,
  Section,
  TextContainer,
} from './style'

const Director = () => (
  <Section>
    <Container small>
      <Content>
        <ImageContainer>
          <ResponsiveImage
            alt="Stipe Barišić - Direktor FSB d.o.o. protupožarna zaštita"
            filename="barisic.jpg"
          />
          <span>
            dipl. Ing. Stipe Barišić
          </span>
        </ImageContainer>
        <TextContainer>
          <Fade bottom distance="50px">
            <h2>
              Riječ direktora
            </h2>
            <p>
              Još od prije 20 godina, od samih početaka stvaranja zakonskog okvira u Republici Hrvatskoj (kao i u regiji), aktivno sudjelujem u izgradnji pretpostavki za visokokvalitetnu pasivnu protupožarnu zaštitu u graditeljstvu i industriji.
            </p>
            <p>
              Kao osnivač i dugogodišnji direktor Promat G.m.b.h. predstavništva u Hrvatskoj, a kasnije kao prokurist Promat građevinski materijali i tehnički direktor Promat SEE, nadasve profesionalnim inženjerskim pristupom postavio sam visoke standarde iz područja pasivne protupožarne zaštite. O tome svjedoče brojne reference od kojih bih kao najznačajnije izdvojio Plivine tvornice u Zagrebu i Savskom Marofu, Tvornica duhana Rovinj, Termoelektrana Plomin 2, TE-TO Zagreb, Hotel Esplanade Zagreb, Hotel Le Meridien Lav Split, HOTO Business Tower, Hypo centar Zagreb, Euro Tower Zagreb, nova međunarodna Zračna luka Zagreb i Zračna luka Dubrovnik.
            </p>
            <p>
              2013. osnovao sam vlastitu tvrtku FSB s kojom sam nastavio raditi na već dokazanom modelu poslovanja - savjetovanje za optimalan odabir projektom zadanih mjera pasivne protupožarne zaštite u graditeljstvu, kao i preuzimanje izvođenja zahtjevnijih projekata. Cilj mi je i dalje, kao i svih ovih godina, zalagati se za profesionalan, nadasve, inženjerski pristup ovome poslu, jer vjerujem da je on jedini dugoročno održiv. Neki od projekata na kojima sam radio u sklopu FSB-a su upravna zgrada Zagrebačke banke na Trgu bana Jelačića u Zagrebu, poslovni centar Adris grupe također u Zagrebu, dvorac Eltz u Vukovaru, Vipnet call centar Zagreb, tvornica lijekova Lukaps u Ludbregu te nova tvornica lijekova Belupo u Koprivnici.
            </p>
            <p>
              Vjerujem da svi s kojima sam do sada surađivao mogu posvjedočiti o mojim sposobnostima i profesionalnosti, a i navedeni projekti govore tome u prilog.
            </p>
          </Fade>
        </TextContainer>
      </Content>
    </Container>
  </Section>
)

export default Director
