import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'
import { primaryColor } from '@styles/theme'

const activeClassName = 'active-link'

export const StyledTopHeader = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;

  @media (max-width: 600px) {
    display: none;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const Contact = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }

  img {
    margin-right: 8px;
  }

`

export const MobileContact = styled.div`

  & > div {
    display: flex;
    align-items: center;
    margin-right: 24px;
    padding: 8px 16px;
    margin-top: 8px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    margin-right: 8px;
  }

`

export const StyledTopHeaderContainer = styled.div`
  
`

export const StyledHeaderContainer = styled.div`
  background: ${primaryColor}
`
export const StyledHeader = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 1100px) {
      display: none;
    }
  }
`

export const StyledLink = styled(Link).attrs({
  activeClassName: activeClassName
})`
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  // text-transform: uppercase;
  font-weight: 400;
  margin: 0 10px;
  padding: 10px 20px;

  &:hover,
  &.${activeClassName} {
    background: rgba(255,255,255, 0.15);
  }
`

export const Logo = styled.div`
  a {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;

    span {
      font-size: 20px;
      font-weight: 500;

      @media (max-width: 600px) {
        max-width: 100px;
        font-size: 16px;
      }
    }
  }
`

export const MenuIcon = styled.button`
  display: none;
  appearance: none;
  border: 0;
  background: transparent;
  outline: 0;

  @media(max-width: 1100px) {
    display: block;
  }
`

export const Right = styled.div`
  display: flex;
  align-items: center;
`

export const LanguageSelector = styled.div`
  position: relative;
  font-size: 13px;

  button {
    appearance: none;
    background: transparent;
    padding: 4px 6px;
    border: 0;
    display: flex;
    align-items: center;
    font-size: 13px;

    img {
      margin-right: 4px;
    }
  }

  &:hover {
    background: #eee;
    & > div {
      display: block;
    }
  }
`

export const Menu = styled.div`
  display: none;
  position: absolute;
  z-index: 2;
  background: #fff;
  top: 22px;

  a {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    padding: 6px;
    
    &:hover {
      text-decoration: none;
      background: #eee;
    }

    img {
      display: block;
      margin-right: 4px;
    }
  }
`

export const MenuBackdrop = styled.div`
  position: fixed;
  background: rgba(0,0,0,0.7);
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${props => props.isVisible ? 'block' : 'none'};
  animation-name: ${props => props.isVisible ? fadeIn : ''};
  animation-duration: 150ms;
  animation-direction: normal;
  animation-timing-function: cubic-bezier(.06,.71,.25,.97);
  animation-fill-mode: forwards;
  opacity: 0;
`
  
export const MobileMenu = styled.div`
  position: fixed;
  z-index: 21;
  background: #fff;
  width: 300px;
  top: 0;
  right: 0;
  height: 100%;
  animation-name: ${props => props.isVisible ? slideIn : ''};
  animation-duration: 250ms;
  animation-delay: 50ms;
  animation-direction: normal;
  animation-timing-function: cubic-bezier(0,.92,.11,1);
  animation-fill-mode: forwards;
  animation-play-state: running;
  transform: translateX(300px);
  display: ${props => props.isVisible ? 'block' : 'none'};

  > header {
    padding: 0 16px;
    height: 60px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;

    > span {
      display: flex;
      align-items: center;
    }

    button {
      appearance: none;
      background: transparent;
      outline: 0;
      border: 0;
    }
  }

  nav {
    // padding: 16px;
  }
`

export const MobileLink = styled(Link).attrs({
  activeClassName: activeClassName
})`
  color: inherit;
  display: block;
  font-size: 15px;
  text-decoration: none;
  padding: 16px 16px;
  border-bottom: 1px solid #eee;

  &.${activeClassName} {
    font-weight: 600;
    background: #fafafa;
  }
`

export const slideIn = keyframes`
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(0);
  }
`

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
