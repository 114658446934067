import styled from 'styled-components'
import { red } from '@styles/theme'

export const Content = styled.div`
  padding: 30px 0;
  line-height: 1.6;
  padding: 30px 0;
  max-width: 800px;
  font-size: 16px;

  a {
    color: ${red};
  }
`