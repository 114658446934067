import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, ProductListImage } from '@components'
import {
  Content,
  ImageContainer,
  Layout,
  DisabledProduct,
  Product,
  ProductsContainer,
  RedirectedProduct,
  Section,
  Sidebar,
  StyledLink,
  StyledSubLink,
} from './style'

const Products = ({
  activeCategory,
}) => {
  const data = useStaticQuery(query)
  const nodes = data.allMarkdownRemark.edges

  const brtvljenjeSubCategories = [
    { name: 'Gorive cijevi', slug: 'gorive-cijevi' },
    { name: 'Negorive cijevi', slug: 'negorive-cijevi' },
    { name: 'Protupožarne zaklopke', slug: 'protupozarne-zaklopke' },
    { name: 'Električni kabeli', slug: 'elektricni-kabeli' },
    { name: 'Dilatacijske fuge', slug: 'dilatacijske-fuge' },
    { name: 'Ostalo', slug: 'ostalo' },
  ]

  const moduliSubCategories = [
    { name: 'S okvirom', slug: 's-okvirom' },
    { name: 'Bez okvira', slug: 'bez-okvira' },
  ]

  return (
    <Section>
      <Container>
        <Layout>
          <Sidebar>
            <h4>
              Kategorije
            </h4>
            <ul>
              <li>
                <StyledLink to="/proizvodi" active={!activeCategory}>
                  Svi proizvodi
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/proizvodi/protupozarno-brtvljenje/" active={activeCategory === 'protupozarno-brtvljenje' || brtvljenjeSubCategories.find(s => s.slug === activeCategory)}>
                  Protupožarno brtvljenje
                </StyledLink>
                {
                  false &&
                  (activeCategory === 'protupozarno-brtvljenje' || brtvljenjeSubCategories.find(s => s.slug === activeCategory)) &&
                  <ul>
                    {
                      brtvljenjeSubCategories.map(s => (
                        <li key={s.slug}>
                          <StyledSubLink to={`/proizvodi/protupozarno-brtvljenje/${s.slug}/`} active={activeCategory === s.slug}>
                            {s.name}
                          </StyledSubLink>
                        </li>
                      ))
                    }
                  </ul>
                }
              </li>
              <li>
                <StyledLink to="/proizvodi/protupozarne-ploce/" active={activeCategory === 'protupozarne-ploce'}>
                  Protupožarne ploče
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/proizvodi/protupozarna-boja-i-premaz/" active={activeCategory === 'protupozarna-boja-i-premaz'}>
                  Protupožarna boja i premaz
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/proizvodi/protupozarni-moduli/" active={activeCategory === 'protupozarni-moduli' || moduliSubCategories.find(s => s.slug === activeCategory)}>
                  Protupožarni moduli
                </StyledLink>
                {
                  false &&
                  (activeCategory === 'protupozarni-moduli' || moduliSubCategories.find(s => s.slug === activeCategory)) &&
                  <ul>
                    {
                      moduliSubCategories.map(s => (
                        <li key={s.slug}>
                          <StyledSubLink to={`/proizvodi/protupozarni-moduli/${s.slug}/`} active={activeCategory === s.slug}>
                            {s.name}
                          </StyledSubLink>
                        </li>
                      ))
                    }
                  </ul>
                }
              </li>
              <li>
                <StyledLink to="/proizvodi/protupozarno-staklo/" active={activeCategory === 'protupozarno-staklo'}>
                  Protupožarno staklo
                </StyledLink>
              </li>
              {/* <li>
                <StyledLink to="/proizvodi/protupozarna-zbuka/" active={activeCategory === 'protupozarna-zbuka'}>
                  Protupožarna žbuka
                </StyledLink>
              </li> */}
              <li>
                <StyledLink to="/proizvodi/protupozarna-vrata/" active={activeCategory === 'protupozarna-vrata'}>
                  Protupožarna vrata
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/proizvodi/silikatne-ploce-za-kamin/" active={activeCategory === 'silikatne-ploce-za-kamin'}>
                  Silikatne ploče za kamin
                </StyledLink>
              </li>
            </ul>
          </Sidebar>
          <ProductsContainer>
            {
              nodes
                .filter(n => !activeCategory || n.node.frontmatter.productCategory.find(cat => cat.slug === activeCategory))
                .map(n => {
                  const image = `proizvodi/${n.node.frontmatter.image}`

                  const redirect = n.node.frontmatter.outgoingLink;
                  if (!!redirect) {
                    return (
                      // navigate to redirect
                      <RedirectedProduct key={n.node.fields.slug} href={redirect}>
                        <ImageContainer>
                          <ProductListImage
                            alt={`${n.node.frontmatter.name} - ${n.node.frontmatter.productCategory[0].label}`}
                            filename={image}
                            style={{
                              maxHeight: 280,
                            }}
                          />
                        </ImageContainer>
                        <Content>
                          <div>
                            {n.node.frontmatter.category}
                          </div>
                          <span>
                            {n.node.frontmatter.name}
                          </span>
                        </Content>
                      </RedirectedProduct>
                    )
                  }

                  const isDisabled = n.node.frontmatter.disabled;
                  if (isDisabled) {
                    return (
                      <DisabledProduct key={n.node.fields.slug}>
                        <ImageContainer>
                          <ProductListImage
                            alt={`${n.node.frontmatter.category} - ${n.node.frontmatter.name}`}
                            filename={image}
                            style={{
                              maxHeight: 200,
                            }}
                          />
                        </ImageContainer>
                        <Content>
                          <div>
                            {n.node.frontmatter.category}
                          </div>
                          <span>
                            {n.node.frontmatter.name}
                          </span>
                        </Content>
                      </DisabledProduct>
                    )
                  }
                  return (
                    <Product key={n.node.fields.slug} to={`/proizvodi${n.node.fields.slug}`}>
                      <ImageContainer>
                        <ProductListImage
                          alt={`${n.node.frontmatter.category} - ${n.node.frontmatter.name}`}
                          filename={image}
                          style={{
                            maxHeight: 200,
                          }}
                        />
                      </ImageContainer>
                      <Content>
                        <div>
                          {n.node.frontmatter.category}
                        </div>
                        <span>
                          {n.node.frontmatter.name}
                        </span>
                      </Content>
                    </Product>
                  )
              })
            }
          </ProductsContainer>
        </Layout>
      </Container>
    </Section>
  )
}

const query = graphql`
  query AllProducts {
    allMarkdownRemark(
      filter: {
        fields: {
          sourceName: {
            eq: "products"
          }
        }
      }
      sort: {
        fields: [frontmatter___favorite]
        order: DESC
      }
    ) {
      edges {
        node {
          fileAbsolutePath
          fields {
            slug
          }
          frontmatter {
            name
            disabled
            image
            outgoingLink
            productCategory {
              label
              slug
            }
          }
        }
      }
    }
  }
`

export default Products
