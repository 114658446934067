import styled from 'styled-components'
import { Link } from 'gatsby'
import { lightGray, red } from '@styles/theme'
import { transition } from '@styles/utils'

export const Section = styled.div`
  padding: 64px 0;

  @media (max-width: 820px) {
    margin: 0 -16px;
  }

  @media (max-width: 780px) {
    padding: 16px 0;
  }

  @media (max-width: 600px) {
    margin: 0;
  }
`

export const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 780px) {
    flex-direction: column;
  }

`

export const Sidebar = styled.aside`
  width: 200px;
  position: sticky;
  top: 0;

  h4 {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 4px;
    padding: 0 6px;
  }

  ul {
    list-style: none;
    padding: 0;

    ul {
      margin-left: 15px;
    }
  }

  @media (max-width: 780px) {
    display: none;
  }

  @media (max-width: 470px) {
    display: none;
  }
`

export const StyledLink = styled(Link)`
  color: ${props => props.active ? '#fff' : 'inherit'};
  font-size: 13px;
  display: inline-block;
  padding: 6px 8px;
  background: ${props => props.active ? red : 'transparent'};
  margin-bottom: 4px;
  text-decoration: none;
  font-weight: ${props => props.active ? 500 : 400};
`

export const StyledSubLink = styled(Link)`
  color: ${props => props.active ? red : 'inherit'};
  font-size: 12px;
  display: inline-block;
  padding: 6px 8px;
  // background: transparent;
  margin-bottom: 4px;
  text-decoration: none;
  font-weight: ${props => props.active ? 500 : 400};
`

export const ProductsContainer = styled.div`
  // flex: 5 5 1000px;
  width: calc(100% - 240px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 780px) {
    width: 100%;
  }
`

export const RedirectedProduct = styled.a`
  background: #fff;
  min-width: 200px;
  max-width: 300px;
  width: calc(25% - 32px);
  position: relative;
  margin: 0 16px 40px;
  color: inherit;
  text-decoration: none;
  transition: ${transition('background,color')};

  @media (max-width: 1036px) {
    width: calc(33% - 32px);
  }

  @media (max-width: 724px) {
    width: calc(50% - 20px);
    margin: 0 5px 10px;
  }

  @media (max-width: 600px) {
    margin: 0 8px 16px;
    width: 100%;
    max-width: unset;
  }

  @media (max-width: 470px) {
    margin: 0 0 16px;
    width: 100%;
    display: flex;
    padding-bottom: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background: #444;
    color: #fff;
  }
`

export const DisabledProduct = styled.div`
background: ${lightGray};
min-width: 200px;
max-width: 300px;
width: calc(25% - 16px - 2px);
position: relative;
margin: 0 8px 16px;
border: 1px solid #eee;
color: inherit;
text-decoration: none;
transition: ${transition('background,color')};

@media (max-width: 1036px) {
  width: calc(50% - 16px - 2px);
  max-width: unset;
}

@media (max-width: 600px) {
  margin: 0 8px 16px;
  width: 100%;
  max-width: unset;
}

@media (max-width: 470px) {
  margin: 0 0 16px;
  width: 100%;
  display: flex;
  padding-bottom: 0;
}

&:last-of-type {
  margin-right: 0;
}
`

export const Product = styled(Link)`
  background: ${lightGray};
  min-width: 200px;
  max-width: 300px;
  width: calc(25% - 16px - 2px);
  position: relative;
  margin: 0 8px 16px;
  border: 1px solid #eee;
  color: inherit;
  text-decoration: none;
  transition: ${transition('background,color')};

  @media (max-width: 1036px) {
    width: calc(50% - 16px - 2px);
    max-width: unset;
  }

  @media (max-width: 600px) {
    margin: 0 8px 16px;
    width: 100%;
    max-width: unset;
  }

  @media (max-width: 470px) {
    margin: 0 0 16px;
    width: 100%;
    display: flex;
    padding-bottom: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background: #444;
    color: #fff;
  }
`

export const Content = styled.div`
  padding: 8px 8px 12px;

  @media (max-width: 470px) {
    width: calc(100% - 100px);
  }

  & > div {
    font-size: 12px;
    opacity: 0.6;
    margin-bottom: 4px;

    @media (max-width: 600px) {
      font-size: 11px;
    }
  }

  & > span {
    font-size: 16px;
    font-weight: 500;

    @media (max-width: 600px) {
      font-size: 15px;
    }
  }
`

export const ImageContainer = styled.div`
  background: #fff;

  @media (max-width: 470px) {
    width: 120px;
  }
`

export const CTA = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  box-sizing: border-box;
  color: inherit;
  transition: ${transition('background')};

  @media (max-width: 600px) {
    padding: 4px 8px;
    font-size: 11px;
  }

  @media (max-width: 470px) {
    display: none;
  }
  
  img {
    margin-left: 8px;
    transition: ${transition('margin')};
  }

  &:hover {
    img {
      margin-left: 12px;
    }
  }
`