import React, { useEffect, useState } from 'react'
import { useDebounce } from '@utils/functions'
import { Input } from './style'

const SearchInput = ({ initialQuery, onChange }) => {
  const [q, setQ] = useState(initialQuery)
  const debouncedValue = useDebounce(q, 500)

  useEffect(() => {
    console.log('Set q')
    setQ(initialQuery)
  }, [initialQuery])

  useEffect(() => {
    if (debouncedValue === undefined) {
      return
    }
    onChange(debouncedValue)
  }, [debouncedValue, onChange])

  return (
    <Input
      onChange={e => setQ(e.target.value)}
      placeholder="Upišite pojam"
      type="text"
      value={q}
    />
  )
}

export default SearchInput