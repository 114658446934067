import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, ProductListImage } from '@components'
import {
  Content,
  DisabledProduct,
  ImageContainer,
  Layout,
  Product,
  ProductsContainer,
  Section,
} from './style'

const MoreProducts = ({
  activeCategory,
  excludes = [],
}) => {
  const data = useStaticQuery(query)
  const nodes = data.allMarkdownRemark.edges

  return (
    <Section>
      <Container>
        <h2>
          Povezani proizvodi
        </h2>
        <Layout>
          <ProductsContainer>
            {
              nodes
                .filter(n => !activeCategory || activeCategory === n.node.frontmatter.productCategory[0].slug)
                .filter(n => excludes.indexOf(n.node.fields.slug) === -1)
                .slice(0, 4)
                .map(n => {
                  const image = `proizvodi/${n.node.frontmatter.image}`
                  const redirect = n.node.frontmatter.outgoingLink;
                  if (!!redirect) {
                    return (
                      // navigate to redirect
                      <RedirectedProduct key={n.node.fields.slug} href={redirect} target="_blank">
                        <ImageContainer>
                          <ProductListImage
                            alt={`${n.node.frontmatter.name} - ${n.node.frontmatter.productCategory[0].label}`}
                            filename={image}
                            style={{
                              maxHeight: 280,
                            }}
                          />
                        </ImageContainer>
                        <Content>
                          <div>
                            {n.node.frontmatter.category}
                          </div>
                          <span>
                            {n.node.frontmatter.name}
                          </span>
                        </Content>
                      </RedirectedProduct>
                    )
                  }

                  const isDisabled = n.node.frontmatter.disabled;
                  if (isDisabled) {
                    return (
                      // navigate to redirect
                      <DisabledProduct key={n.node.fields.slug}>
                        <ImageContainer>
                          <ProductListImage
                            alt={`${n.node.frontmatter.name} - ${n.node.frontmatter.productCategory[0].label}`}
                            filename={image}
                            style={{
                              maxHeight: 280,
                            }}
                          />
                        </ImageContainer>
                        <Content>
                          <div>
                            {n.node.frontmatter.category}
                          </div>
                          <span>
                            {n.node.frontmatter.name}
                          </span>
                        </Content>
                      </DisabledProduct>
                    )
                  }
                  return (
                    <Product key={n.node.fields.slug} to={`/proizvodi${n.node.fields.slug}`}>
                      <ImageContainer>
                        <ProductListImage
                          alt={`${n.node.frontmatter.name} - ${n.node.frontmatter.productCategory[0].label}`}
                          filename={image}
                          style={{
                            maxHeight: 280,
                          }}
                        />
                      </ImageContainer>
                      <Content>
                        <div>
                          {n.node.frontmatter.category}
                        </div>
                        <span>
                          {n.node.frontmatter.name}
                        </span>
                      </Content>
                    </Product>
                  )
              })
            }
          </ProductsContainer>
        </Layout>
      </Container>
    </Section>
  )
}

const query = graphql`
  query MoreProducts {
    allMarkdownRemark(
      filter: {
        fields: {
          sourceName: {
            eq: "products"
          }
        }
      }
      sort: {
        fields: [frontmatter___favorite]
        order: DESC
      }
    ) {
      edges {
        node {
          fileAbsolutePath
          fields {
            slug
          }
          frontmatter {
            name
            image
            productCategory {
              label
              slug
            }
          }
        }
      }
    }
  }
`

export default MoreProducts
