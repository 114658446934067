import React from 'react'
import { Container } from '@components'
import * as S from './styles'

const Notice = ({ children }) => {
  return (
    <Container>
      <S.Content>
        {children}
      </S.Content>
    </Container>
  )
}

export default Notice