import React from 'react'
import { Container } from '@components'
import { Content } from './style'

const Privacy = () => (
  <Container>
    <Content>
      <h2>
        FSB d.o.o. za savjetovanje i trgovinu
      </h2>
      <dl>
        <dt>MB:</dt>
        <dd>4107063</dd>
        <br />
        <dt>MBS:</dt>
        <dd>080870434</dd>
        <br />
        <dt>OIB:</dt>
        <dd>48082997755</dd>
        <br />
        <dt>EUID:</dt>
        <dd>HRSR.080870434</dd>
        <br />
        <dt>Račun</dt>
        <dd> u Raiffeisenbanci Austria d.d.</dd>
        <br />
        <dt>Žiro račun:</dt>
        <dd>HR792484008-1106684052 Raiffeisenbank Austria d.d.</dd>
        <br />
        <dt>Iznos temeljnog kapitala</dt>
        <dd>40.000,00 kn, uplaćen u cijelosti</dd>
        <br />
        <dt>Tel:</dt>
        <dd>+38513496811</dd>
        <br />
        <dt>Mob:</dt>
        <dd>+38598218359</dd>
        <br />
        <dt>E-mail:</dt>
        <dd>info@fsb-zagreb.hr</dd>
        <br />
        <dt>Tvrtka upisana u trgovački registar:</dt>
        <dd>Trgovački sud u Zagrebu</dd>
      </dl>
    </Content>
  </Container>
)

export default Privacy
