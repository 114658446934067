import React from 'react'
import { Container } from '@components'
import { trackGAEvent, useSiteData } from '@utils/functions'
import {
  Contact,
  Content,
  Section,
} from './style'

const Contacts = () => {
  const site = useSiteData()
  return (
    <Section>
      <Container>
        <Content>
          <Contact>
            <span>
              Email
            </span>
            <div>
              <a href={`mailto:${site.email}`} onClick={() => trackGAEvent({
                  category: 'contact',
                  action: 'email',
                  label: 'contact_page',
                  isConversion: true,
                })}>
                {site.email}
              </a>
            </div>
          </Contact>
          <Contact>
            <span>
              Telefon
            </span>
            <div>
              <a href={`tel:${site.phoneLink}`}  onClick={() => trackGAEvent({
                  category: 'contact',
                  action: 'phone',
                  label: 'contact_page',
                  isConversion: true,
                })}>
                {site.phone}
              </a>
              <br />
              <a href={`tel:${site.phoneLink2}`} onClick={() => trackGAEvent({
                  category: 'contact',
                  action: 'phone',
                  label: 'contact_page',
                  isConversion: true,
                })}>
                {site.phone2}
              </a>
            </div>
          </Contact>
          <Contact>
            <span>
              Adresa
            </span>
            <div>
              <a href="https://www.google.com/maps/place/FSB+d.o.o./@45.811092,15.873422,17z/data=!3m1!4b1!4m5!3m4!1s0x4765d1a06eb53a81:0xf767673f33bcec1!8m2!3d45.811092!4d15.875616" onClick={() => trackGAEvent({
                  category: 'contact',
                  action: 'address',
                  label: 'contact_page',
                })}>
                {site.address}<br />
                {site.postalCode} {site.addressLocality}, Hrvatska
              </a>
            </div>
          </Contact>
        </Content>
      </Container>
    </Section>
  )
}

export default Contacts
