import React from 'react'
import { Container } from '@components'
import { Content } from './style'
import Eu1 from '@assets/images/eu1.png'
import Eu2 from '@assets/images/eu2.svg'
import Eu3 from '@assets/images/eu3.svg'


const EuProjectDescription = () => (
  <Container>
    <Content>
      <img height={70} src={Eu1} alt="Europska Unija: Zajedno do EU fondova" />
      <img height={80} src={Eu2} alt="Operativni program konkurentnost i kohezija" />
      <img height={80} src={Eu3} alt="Europski strukturni i investicijski fondovi" />
      <h2>
        Podaci o projektu:
      </h2>
      <dl>
        <dt>
          Naziv projekta
        </dt>
        <dd>
          Certifikacijom proizvoda poduzeća FSB d.o.o. do tržišta
        </dd>
        <dt>
          Opći cilj
        </dt>
        <dd>
          Razvoj kapaciteta FSB-a za rast na regionalnim, nacionalnim i međunarodnim tržištima i inovacijskih procesa
        </dd>
        <dt>
          Specifični ciljevi
        </dt>
        <dd>
          <ul>
            <li>
              povećani prihodi od prodaje poduzeća za &gt;36%, povećani prihodi od izvoza za minimalno 31%
            </li>
            <li>
              veća potražnja za certificiranim proizvodima te pribavljanje Izjave o sukladnosti za proizvode čime će se povećati konkurentnost poduzeća Fsb d.o.o. na tržištu
            </li>
            <li>
              minimalno 8 certificiranih proizvoda poduzeća Fsb d.o.o.
            </li>
          </ul>
        </dd>
        <dt>
          Rezultati
        </dt>
        <dd>
          Rezultat projekta bit će minimalno 8 certificiranih proizvoda.
        </dd>
        <dt>
          Ukupna vrijednost projekta
        </dt>
        <dd>
          1.442.043,75 HRK
        </dd>
        <dt>
          EU sufinanciranje
        </dt>
        <dd>
          920.999,43 HRK
        </dd>
        <dt>
          Razdoblje provedbe projekta
        </dt>
        <dd>
          18.11.2019. - 18.06.2022.
        </dd>
        <dt>
          Kontakt osoba
        </dt>
        <dd>
          Stipe Barišić, +385 1 3496 811, info@fsb-zagreb.hr
        </dd>
      </dl>
      <ul>
        <li>
          <a href="https://www.strukturnifondovi.hr">https://www.strukturnifondovi.hr</a>
        </li>
        <li>
        <a href="https://strukturnifondovi.hr/eu-fondovi/">https://strukturnifondovi.hr/eu-fondovi/</a>
        </li>
        <li>
          <a href="https://strukturnifondovi.hr/eu-fondovi/esi-fondovi-2014-2020/op-konkurentnost-i-kohezija/">https://strukturnifondovi.hr/eu-fondovi/esi-fondovi-2014-2020/op-konkurentnost-i-kohezija/</a>
        </li>
      </ul>
    </Content>
  </Container>
)

export default EuProjectDescription
