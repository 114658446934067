import { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export const useSiteData = () => {
  const siteQuery = graphql`
    query siteData {
      site {
        siteMetadata {
          title
          description
          siteUrl
          address
          addressLocality
          addressRegion
          postalCode
          addressCountry
          image
          linkedin
          phone
          phoneLink
          phone2
          phoneLink2
          email
        }
      }
    }
  `
  const data = useStaticQuery(siteQuery)
  return data.site.siteMetadata
}

export const trackConversion = () => {
  window.gtag('event', 'conversion', {'send_to': 'AW-11078182465/GMJOCITDsokYEMHMvqIp'})
}

export const trackGAEvent = ({
  action,
  category,
  isConversion,
  label,
  value,
}) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
  if (isConversion) {
    trackConversion()
  }
}

export const useClickOutside = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    }
  })
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}
