import React from 'react'
import { Fade } from 'react-reveal'
import {
  Container,
  ResponsiveImage,
} from '@components'
import {
  Content,
  Highlight,
  ImageContainer,
  Section,
  Text,
} from './style'

const Construction = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Text>
            <Fade bottom distance="50px">
              <Highlight>
              Prilikom izvođenja (realizacije) naših rješenja, vodimo se trima ključnim elementima: pouzdanost, kvaliteta i fleksibilnost. 
              </Highlight>
            </Fade>
            <Fade bottom distance="50px">
              <p>
                U svrhu ispunjavanja visoko postavljenih zahtjeva surađujemo s najboljim monterskim tvrtkama u Republici Hrvatskoj. Svi naši partneri prolaze dodatnu obuku za izvođenje naših rješenja. Njihova se učinkovitost u provođenju rješenja u praksi očituje u brojnim uspješno odrađenim projektima.
              </p>
              <p>
                <span>
                  Iako je povjerenje važna sastavnica djelovanja tvrtke FSB d.o.o., svi izvedbeni radovi prolaze rigoroznu kontrolu kvalitete naših inženjera kako bismo svojim klijentima osigurali siguran prolazak na tehničkom pregledu u zadanom roku.
                </span>
              </p>

              <h3>
                Protupožarno brtvljenje za sve vrste instalacija
              </h3>
              <p>
                Protupožarno brtvljenje ima važnu ulogu na svakoj građevini jer u slučaju požara sprječava širenje vatre i dima preko granica požarnih sektora.
                Zbog sve veće tehnološke opremljenosti modernih građevina, gustoća svih vrsta instalacija je u porastu pa su tako i izazovi za ispravno izvršenje protupožarnih brtvljenja sve zahtjevniji.
              </p>
              <p>
                <i>
                  Radove iz ovog područja zaštite od požara smiju izvoditi isključivo stručno osposobljene osobe.
                </i>
              </p>
              <h3>
                Protupožarna zaštita ventilacijskih kanala i kanali za odvod dima i topline
              </h3>
              <p>
                Kanali za odvod dima i topline, kao i kanali za dopremu svježeg zraka u sigurne zone, projektiraju se i izvode kako bi zadovoljili propisane standarde za zaštitu od požara tih instalacija.
              </p>
              <p>
                Najčešća u praksi primijenjena rješenja izvode se s pločastim materijalima koji svojim karakteristikama zadovoljavaju sve zahtjeve koji se u eksploataciji nameću:
              </p>
              <ul>
                <li>
                  toplinska provodljivost
                </li>
                <li>
                  mehanička nosivost
                </li>
                <li>
                  otpornost na vlagu
                </li>
                <li>
                  očuvanje cjelovitosti
                </li>
              </ul>
              <p>
                <i>
                  Radove iz ovog područja zaštite od požara smiju izvoditi isključivo stručno osposobljene osobe.
                </i>
              </p>
              <h3>
                Vatrozaštita u rafinerijama
              </h3>
              <p>
                Djelovanje vatre na vitalne dijelove postrojenja u petrokemijskoj industriji ispituje se temeljem ugljikovodične krivulje požara. Ovom se krivuljom preslikava gorenje ugljikovodika gdje nakon 30 minuta gorenja temperatura poraste preko 1000 °C. Korištenjem vatrootporne žbuke na bazi vermikulita i Portland cementa možemo izgraditi zaštitni sloj  otporan na djelovanje vatre, a istovremeno postojan na djelovanje atmosferilija i curenje ulja i maziva.
              </p>
              <p>
                <i>
                  Radove iz ovog područja zaštite od požara smiju izvoditi isključivo stručno osposobljene osobe.
                </i>
              </p>
              <h3>
                Protupožarna zaštita u farmaceutskoj industriji
              </h3>
              <p>
                Farmaceutska industrija vrlo je specifična  u smislu zahtjeva zaštite od požara. Svakodnevnim korištenjem kemikalija koje su najčešće zapaljive i mogu stvarati eksplozivne pare odabir rješenja za zaštitu od požara vrlo je zahtjevan. Izgradnja sustava za ventilaciju i odvod nastalih para podrazumijeva korištenje polikarbonatnih cjevovoda, a oblaganjem istih s negorivim materijalima postižemo sigurnost od požara.
              </p>
              <p>
                <i>
                  Radove iz ovog područja zaštite od požara smiju izvoditi isključivo stručno osposobljene osobe.
                </i>
              </p>
              <h3>
                Protupožarne staklene stijene
              </h3>
              <p>
                Protupožarni zahtjevi za staklene stijene jednaki su kao i za ostale pregrade koje se nalaze na granicama požarnih sektora. Iz tog razloga protupožarna stakla se ispituju u sustavu cjelokupne konstrukcije koja se sastoji od okvira, protupožarnog stakla i raznih protupožarnih brtvenih elemenata. Protupožarne staklene stijene nastaju kao gotov proizvod izgradnjom na gradilištu, kao i sve ostale pregrade.
              </p>
              <p>
                <i>
                  Radove iz ovog područja zaštite od požara smiju izvoditi isključivo stručno osposobljene osobe.
                </i>
              </p>
              <h3>
                Protupožarna vrata
              </h3>
              <p>
                Protupožarna vrata najčešći su i najprepoznatljiviji element za zaštitu od požara na građevini.
              </p>
              <p>
                Susrećemo ih u raznim oblicima (jednokrilna, dvokrilna, klizna, djelomično ostakljena, potpuno ostakljena i slično).
              </p>
              <p>
                Primjenom raznih vrsta materijala i kombiniranjem s protupožarnim staklom uspijevaju se pomiriti zahtjevi za estetskim oblikovanjem i zaštitom od požara.
              </p>
              <p>
                Ispitivanja svih tipova protupožarnih vrata provode se prema jasno definiranim standardima.
              </p>
              <p>
                <i>
                  Radove ugradnje na objektu smiju izvoditi isključivo stručno osposobljene osobe.
                </i>
              </p>
            </Fade>
          </Text>
          <ImageContainer>
            <ResponsiveImage filename="zgrada.jpg" alt="Optimizacija troškova protupožarne zaštite  - FSB d.o.o. protupožarna zaštita" />
          </ImageContainer>
        </Content>
      </Container>
    </Section>
  )
}

export default Construction
