import React from 'react'
import { CookiesNotification, Footer, Header, SEO } from '@components'
import { GlobalStyles } from './style'

const Page = ({ children, meta }) => {
  return (
    <>
      <SEO meta={meta} />
      <GlobalStyles />
      <Header />
      <main>
        {children}
      </main>
      <CookiesNotification />
      <Footer />
    </>
  )
}

export default Page;
