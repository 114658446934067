import styled from 'styled-components'
import { lightGray } from '@styles/theme'
import { transition } from '@styles/utils'

export const Section = styled.section`
  background: ${lightGray};
  padding: 32px 0 48px;

  h2 {
    font-size: 28px;
    font-weight: 500;
  }
`

export const ProjectsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const Project = styled.div`
  margin-right: 24px;
  background: #fff;
  flex: 1;
  position: relative;
  padding-bottom: 56px;

  @media (max-width: 600px) {
    display: flex;
    margin-right: 0;
    padding-bottom: 0;
    margin-bottom: 16px;
  }

  & > div {
    &:first-of-type {
      @media(max-width: 600px) {
        width: 30%;
      }
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
`

export const Content = styled.div`
  padding: 16px 16px 0;

  @media (max-width: 600px) {
    flex: 3;
    padding-bottom: 32px;
    min-height: 60px;
  }

  & > span {
    font-size: 20px;

    @media (max-width: 1000px) {
      font-size: 16px;
    }
  }

  a {
    position: absolute;
    bottom: 16px;
    left: 16px;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;

    @media (max-width: 600px) {
      left: calc(30% + 16px);
      bottom: 8px;
    }
    
    img {
      margin-left: 8px;
      transition: ${transition('margin')};
    }

    &:hover {
      img {
        margin-left: 12px;
      }
    }
  }
`
