import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const ResponsiveImage = (props) => {
  const data = useStaticQuery(imagesQuery)
  const image = data.images.edges.find(n => {
    return n.node.relativePath === props.filename;
  });

  if (!image) {
    return null
  }

  return (
    <Img
      alt={props.alt}
      fluid={image.node.childImageSharp.fluid}
      style={{ ...props.style, maxWidth: 1000, margin: 'auto' }}
    />
  ) 
};

const imagesQuery = graphql`
  query {
    images: allFile(filter: {sourceInstanceName: { eq: "images" }}) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxHeight: 200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`

export default ResponsiveImage