import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Container } from '@components'
import Arrow from '@assets/images/arrow_white.svg'
import Chevron from '@assets/images/chevron.svg'
import Eu1 from '@assets/images/eu1.png'
import {
  Content,
  HeroContainer,
  Products,
  ProductCategory,
  StyledHero,
  StyledHeroContainer,
} from './style'

const Hero = () => {
  const data = useStaticQuery(query);
  const image = data.desktop.childImageSharp.fluid
  return (
    <HeroContainer>
      <Container>
        <StyledHeroContainer>
          <StyledHero
            Tag="div"
            fluid={image}
            backgroundColor="#555"
          >
            <Content>
              <Link to="/projekt-eu">
                <img height={60} src={Eu1} alt="Europska Unija: Zajedno do EU fondova" />
              </Link>
              <h1>
                Optimalna rješenja pasivne protupožarne zaštite
              </h1>
              <Link className="link" to="/usluge/" aria-label="Saznaj više o uslugama i rješenjima pasivne protupožarne zaštite">
                <span>
                  Više informacija
                </span>
                <img src={Arrow} alt="Više informacija" height={26} />
              </Link> 
            </Content>
          </StyledHero>
          <Products>
            <h4>
              Proizvodi
            </h4>
            <p>
              U svojoj ponudi nudimo široku paletu proizvoda renomiranih svjetskih proizvođača na tržištu te izvođenje rješenja pasivne protupožarne zaštite.
            </p>
            <ProductCategory to="/proizvodi/protupozarno-brtvljenje/">
              <h5>
                Protupožarno brtvljenje
              </h5>
              <img src={Chevron} height={18} alt="" />
            </ProductCategory>
            <ProductCategory to="/proizvodi/protupozarne-ploce/">
              <h5>
                Protupožarne ploče
              </h5>
              <img src={Chevron} height={18} alt="" />
            </ProductCategory>
            <ProductCategory to="/proizvodi/protupozarna-boja-i-premaz/">
              <h5>
                Protupožarna boja i premaz
              </h5>
              <img src={Chevron} height={18} alt="" />
            </ProductCategory>
            <ProductCategory to="/proizvodi/protupozarni-moduli/">
              <h5>
                Protupožarni moduli
              </h5>
              <img src={Chevron} height={18} alt="" />
            </ProductCategory>
            <ProductCategory to="/proizvodi/protupozarno-staklo/">
              <h5>
                Protupožarno staklo
              </h5>
              <img src={Chevron} height={18} alt="" />
            </ProductCategory>
            <ProductCategory to="/proizvodi/protupozarna-zbuka/">
              <h5>
                Protupožarna žbuka
              </h5>
              <img src={Chevron} height={18} alt="" />
            </ProductCategory>
            <ProductCategory to="/proizvodi/protupozarna-vrata/">
              <h5>
                Protupožarna vrata
              </h5>
              <img src={Chevron} height={18} alt="" />
            </ProductCategory>
            <ProductCategory to="/proizvodi/silikatne-ploce-za-kamin/">
              <h5>
                Silikatne ploče za kamin
              </h5>
              <img src={Chevron} height={18} alt="" />
            </ProductCategory>
          </Products>
        </StyledHeroContainer>
      </Container>
    </HeroContainer>
  )
}

const query = graphql`
  query {
    desktop: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Hero
