import React from 'react'
import { trackGAEvent } from '@utils/functions'
import { StyledButton } from './style'
import Linkedin from '@assets/images/linkedin.svg'

const ShareButton = ({
  shareId,
  url,
}) => {
  const handleClick = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`,
      '',
      '_blank, width=500, height=500, resizable=yes, scrollbars=yes'
    );
    trackGAEvent({
      category: 'social',
      action: 'share',
      label: shareId,
    })
  }

  return (
    <StyledButton onClick={handleClick}>
      <img src={Linkedin} alt="Linkedin" height={16} />
      Share 
    </StyledButton>
  )
}

export default ShareButton
