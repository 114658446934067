import styled from 'styled-components'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { transition } from '@styles/utils'
import { red } from '@styles/theme'

export const HeroContainer = styled.section`
  background: #f5f5f5;
`

export const StyledHeroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px 0;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    padding: 0;
    margin: 0 -15px;
  }
`

export const StyledHero = styled(BackgroundImage)`
  flex: 2;
  height: 80vh;
  min-height: 300px;
  max-height: 450px;
  padding: 30px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  align-items: flex-end;

  @media (max-width: 1000px) {
    margin-bottom: 30px;
  }

  @media (max-width: 600px) {
    padding: 30px 15px;
    margin: 0;
  }
`

export const Content = styled.div`
  position: relative;
  z-index: 2;
  max-width: 500px;

  h1 {
    display:block;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.5;

    @media (max-width: 600px) {
      font-size: 24px;
      font-weight: 500;
    }
  }

  a.link {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    margin-top: 18px;
    
    img {
      margin-left: 8px;
      transform: translateY(-2px);
      transition: ${transition('margin')};
    }

    &:hover {
      img {
        margin-left: 12px;
      }
    }
  }
`

export const Products = styled.div`
  flex: 1;
  background: #fff;
  margin-left: 50px;
  box-sizing: border-box;
  padding: 25px;

  @media (max-width: 1000px) {
    margin-left: 0;
  }

  @media (max-width: 600px) {
    padding: 30px 15px 0;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
  }
  
  p {
    font-size: 14px;
    line-height: 1.5;
  }
`

export const ProductCategory = styled(Link)`
  display: flex;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  color: inherit;
  margin: 5px;
  transition: ${transition('background')};

  h5 {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }

  &:hover {
    color: ${red};
  }

  @media (max-width: 1000px) {
    border: 1px solid #eee;
    background: #f7f7f7;
    padding: 15px;
    margin: 15px 0;
    color: inherit;
    justify-content: space-between;

    &:hover {
      color: inherit;
      background: #eaeaea;
    }
  }
`
