import React from 'react'
import { Container } from '@components'
import { Content, Section } from './style'

const AlternativeHero = ({
  description,
  title,
  slim,
  subtitle,
}) => {
  return (
    <Section slim={slim}>
      <Container>
        <Content>
          {
            subtitle &&
            <h2>
              {subtitle}
            </h2>
          }
          <h1>
            {title}
          </h1>
          <p>
            {description}
          </p> 
        </Content>
      </Container>
    </Section>
  )
}

export default AlternativeHero
