import React from 'react'
import { Fade } from 'react-reveal'
import {
  Container,
} from '@components'
import {
  Content,
  Section,
  Text,
} from './style'

const Testing = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Text>
            <Fade bottom distance="50px">
              <p>
              Dosad smo ispitali:
              </p>
              <ul>
                <li>
                  FSP 90 – samostalni protupožarni parapet
                </li>
                <li>
                  FSR-A Protupožarna revizijska vrata.
                </li>
              </ul>  
              <p>
                Osim vlastitih proizvoda pasivne protupožarne zaštite, u mogućnosti smo ispitati i specifičnu konstrukciju koja se može pojaviti na nekoj od građevina i time osigurati i valjanu certifikacijsku dokumentaciju za tehnički pregled.
              </p>
            </Fade>
          </Text>
        </Content>
      </Container>
    </Section>
  )
}

export default Testing
