import React from 'react'
import { Fade } from 'react-reveal'
import { Container, ResponsiveImage } from '@components'
import { Column, ColumnsContainer, Image } from './style'

const Bullets = () => (
  <Container small>
    <Image>
      <ResponsiveImage
        alt="Certifikati kvalitete tvrtke FSB d.o.o. protupožarna zaštita"
        filename="certificates.png"
      />
    </Image>
    <ColumnsContainer>
      <Fade bottom distance="50px">
        <Column>
          <h2>
            Stručnost
          </h2>
          <p>
            Posjedujemo bogato iskustvo i znanje u području pasivne protupožarne zaštite u građevinarstvu. 
          </p>
          <p>
            Tvrtka njeguje izvrsnost i profesionalni timski rad što dokazuju različiti projekti koji se uspješno realiziraju (više od 25 projekata godišnje), kao i brojne reference.
          </p>
        </Column>
      </Fade>
      <Fade bottom distance="50px">
        <Column>
          <h2>
            Kvaliteta i izvrsnost
          </h2>
          <p>
            Ispunjavamo zahtjeve međunarodne norme ISO 9001 koja se odnosi na upravljanje kvalitetom. Upravljanje i osiguranje kvalitete i težnja za izvrsnošću obuhvaća i posjedovanje specifičnih znanja koja su nužna za uspješnu realizaciju građevinskih projekata.
          </p>
        </Column>
      </Fade>
    </ColumnsContainer>
  </Container>
)

export default Bullets
