import styled from 'styled-components'
import { Link } from 'gatsby'
import { red } from '@styles/theme'
import { transition } from '@styles/utils'

export const Section = styled.div`
  background: #f4f4f4;
  padding: 32px 0;

  @media (max-width: 820px) {
    margin: 0 -16px;
  }

  @media (max-width: 780px) {
    padding: 16px 0;
    margin: 0;
  }

  @media (max-width: 600px) {
    margin: 0;
  }

  h2 {
    font-size: 28px;
    font-weight: 500;
    margin-left: 5px;

    @media (max-width: 600px) {
      font-size: 22px;
    }
  }
`

export const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 780px) {
    flex-direction: column;
  }

`

export const StyledLink = styled(Link)`
  color: ${props => props.active ? '#fff' : 'inherit'};
  font-size: 13px;
  display: inline-block;
  padding: 6px 8px;
  background: ${props => props.active ? red : 'transparent'};
  margin-bottom: 4px;
  text-decoration: none;
  font-weight: ${props => props.active ? 500 : 400};
`

export const ProductsContainer = styled.div`
  // flex: 5 5 1000px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -16px;
  width: 100%;

  @media (max-width: 780px) {
    width: 100%;
    margin: 0;
  }
`

export const Product = styled(Link)`
  background: #fff;
  min-width: 200px;
  max-width: 300px;
  width: calc(25% - 32px);
  position: relative;
  margin: 0 16px 40px;
  color: inherit;
  text-decoration: none;
  transition: ${transition('background,color')};

  @media (max-width: 1036px) {
    width: calc(33% - 32px);
  }

  @media (max-width: 724px) {
    width: calc(50% - 20px);
    margin: 0 5px 10px;
  }

  @media (max-width: 600px) {
    margin: 0 8px 16px;
    width: 100%;
    max-width: unset;
  }

  @media (max-width: 470px) {
    margin: 0 0 16px;
    width: 100%;
    display: flex;
    padding-bottom: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background: #444;
    color: #fff;
  }
`

export const RedirectedProduct = styled.a`
  background: #fff;
  min-width: 200px;
  max-width: 300px;
  width: calc(25% - 32px);
  position: relative;
  margin: 0 16px 40px;
  color: inherit;
  text-decoration: none;
  transition: ${transition('background,color')};

  @media (max-width: 1036px) {
    width: calc(33% - 32px);
  }

  @media (max-width: 724px) {
    width: calc(50% - 20px);
    margin: 0 5px 10px;
  }

  @media (max-width: 600px) {
    margin: 0 8px 16px;
    width: 100%;
    max-width: unset;
  }

  @media (max-width: 470px) {
    margin: 0 0 16px;
    width: 100%;
    display: flex;
    padding-bottom: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background: #444;
    color: #fff;
  }
`

export const DisabledProduct = styled(Product)`
  &:hover {
    background: inherit;
    color: inheit;
  }
`

export const Content = styled.div`
  padding: 12px 12px 16px;

  @media (max-width: 600px) {
    padding: 12px 8px 12px;
  }

  @media (max-width: 470px) {
    width: calc(100% - 100px);
  }

  & > div {
    font-size: 12px;
    opacity: 0.6;
    margin-bottom: 4px;

    @media (max-width: 600px) {
      font-size: 11px;
    }
  }

  & > span {
    font-size: 18px;
    font-weight: 500;

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
`

export const ImageContainer = styled.div`
background: #fff;

  @media (max-width: 470px) {
    width: 120px;
  }
`

export const CTA = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  box-sizing: border-box;
  color: inherit;
  transition: ${transition('background')};

  @media (max-width: 600px) {
    padding: 4px 8px;
    font-size: 11px;
  }

  @media (max-width: 470px) {
    display: none;
  }
  
  img {
    margin-left: 8px;
    transition: ${transition('margin')};
  }

  &:hover {
    img {
      margin-left: 12px;
    }
  }
`