import React from 'react'
import { Link } from 'gatsby'
import { Container } from '@components'
import { Content, Section } from './style'

const Contact = () => (
  <Section>
    <Container small>
      <Content>
        <h4>
          Javite nam se s povjerenjem
        </h4>
        <p>
          Za sva dodatna pitanja, stojimo Vam na raspolaganju
        </p>
        <Link to="/kontakt">
          Kontaktirajte nas
        </Link>
      </Content>
    </Container>
  </Section>
)

export default Contact
