import styled from 'styled-components'
import { transition } from '@styles/utils'

const bgColor = '#ddd'

export const Form = styled.form`
  transition: ${transition('background')};
  background: ${props => props.expanded ? bgColor : 'transparent'};
  margin-right: 15px;
  padding-left: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 30px;
  
  input {
    border: 0;
    transition: ${transition('width')};
    width: ${props => props.expanded ? 140 : 0}px;
    background: transparent;
    outline: 0;
  }

  button {
    border: 0;
    background: transparent;
    height: 30px;
    width: 30px;
    padding: 0;
    border-radius: 4px;
    transition: ${transition('background')};
    cursor: pointer;

    &:hover {
      background: ${bgColor};
    }
  }
`

export const Input = styled.input`
  border: 0;
`